import React from 'react';

import github from '../assets/github.svg';
import linkedin from '../assets/linkedin.svg';

import styles from './socialbar.module.css';

export default () => (
  <div className={styles.container}>
    <a href="https://www.github.com/calmdev" className={styles.github}>
      <img src={github} className={styles.icon} alt="Github" />
    </a>
    <a href="https://www.linkedin.com/in/levi-lewis-14a83a7a/">
      <img src={linkedin} className={styles.icon} alt="LinkedIn" />
    </a>
  </div>
)