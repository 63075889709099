import React from 'react';
import Gravatar  from 'react-gravatar';
import SocialBar from './socialbar';
import MobileHeader from './mobileheader';

import './global.css';
import styles from './layout.module.css';

export default (props) => (
  <div className={styles.flex}>
    <div className={styles.header}>
      <MobileHeader />
      <a href="/">
        <Gravatar email="levi@calmdev.com" size={100} className={styles.avatar} />
      </a>
    </div>
    <div className={styles.container}>
      <div className={styles.splash}>
        <div className={styles.name}>
          <h1>Levi Lewis</h1>
          <h2>Web Developer</h2>
          <h3>
            Fan of <span className="yellow">javascript</span>. Subaru lover. Collector of web stickers.
          </h3>
        </div>
      </div>
      <div className={styles.content}>
        <SocialBar />
        {props.children}
      </div>
    </div>
  </div>
);