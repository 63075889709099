import React from 'react';

import styles from './mobileheader.module.css';

export default props => (
  <div className={styles.container}>
    <h1 className={styles.header}>
      Levi Lewis
    </h1>
    <h2 className={styles.subheader}>
      Web Developer
    </h2>
  </div>
)